<template>
    <div :class="{ show: show }" class="header-search">
        <!-- <i class="el-icon-search" @click.stop="click" /> -->
        <i class="iconfont icon-sousuo" v-show="showSearchIcon" />
        <el-select
            @focus="setMinWidth"
            style="width:100%"
            ref="headerSearchSelect"
            v-model="search"
            :remote-method="querySearch"
            filterable
            default-first-option
            remote
            :placeholder="showSearchIcon ? '菜单搜索' : ''"
            class="header-search-select"
            @change="change"
            @blur=";(showSearchIcon = true), (search = '')"
        >
            <el-option
                :style="{ 'min-width': minWidth + 2 + 'px' }"
                v-for="item in options"
                :key="item.path"
                :value="item"
                :label="item.title.join(' > ')"
            />
        </el-select>
    </div>
</template>

<script>
// fuse is a lightweight fuzzy-search module
// make search results more in line with expectations
import Fuse from 'fuse.js'
import path from 'path'
import router from '@/router'
import axios from 'axios'
export default {
    name: 'HeaderSearch',
    data() {
        return {
            search: '',
            options: [],
            searchPool: [],
            show: true,
            showSearchIcon: true,
            fuse: undefined,
            menuList: []
        }
    },
    computed: {
        routes() {
            return router.options.routes
        }
    },
    watch: {
        routes() {
            this.searchPool = this.generateRoutes(this.routes)
        },
        searchPool(list) {
            this.initFuse(list)
        },
        show(value) {
            if (value) {
                document.body.addEventListener('click', this.close)
            } else {
                document.body.removeEventListener('click', this.close)
            }
        }
    },
    created() {
        let dict = axios.getCurrentMenus()
        let menus = Object.values(dict)
        if (
            menus &&
            menus[0] &&
            menus[0].children &&
            menus[0].children.length > 0
        ) {
            this.menuList = menus[0].children
        }
        console.log(this.menuList)
    },
    mounted() {
        console.log(this.routes)
        // this.searchPool = this.generateRoutes(this.routes)
        this.searchPool = this.generateMenus(this.menuList)
        // console.log(this.generateMenus(this.menuList))
    },
    methods: {
        setMinWidth(val) {
            this.showSearchIcon = false
            this.minWidth = val.srcElement.clientWidth
        },
        click() {
            this.show = !this.show
            if (this.show) {
                this.$refs.headerSearchSelect &&
                    this.$refs.headerSearchSelect.focus()
            }
        },
        close() {
            this.$refs.headerSearchSelect &&
                this.$refs.headerSearchSelect.blur()
            this.options = []
            this.show = false
        },
        change(val) {
            this.$router.push(val.path)
            this.search = ''
            this.options = []
            this.$emit('hideMenu')
            //   this.$nextTick(() => {
            //     this.show = false
            //   })
        },
        initFuse(list) {
            this.fuse = new Fuse(list, {
                shouldSort: true,
                threshold: 0.4,
                location: 0,
                distance: 100,
                maxPatternLength: 32,
                minMatchCharLength: 1,
                keys: [
                    {
                        name: 'title',
                        weight: 0.7
                    },
                    {
                        name: 'path',
                        weight: 0.3
                    }
                ]
            })
        },
        // Filter out the routes that can be displayed in the sidebar
        // And generate the internationalized title
        generateRoutes(routes, basePath = '/', prefixTitle = []) {
            let res = []
            for (const router of routes) {
                // skip hidden router
                // if (router.hidden) {
                //   continue;
                // }
                const data = {
                    path: path.resolve(basePath, router.path),
                    title: [...prefixTitle]
                }
                if (router.meta && router.meta.title) {
                    data.title = [...data.title, router.meta.title]
                    if (router.redirect !== 'noRedirect') {
                        // only push the routes with title
                        // special case: need to exclude parent router without redirect
                        res.push(data)
                    }
                }
                // recursive child routes
                if (router.children) {
                    const tempRoutes = this.generateRoutes(
                        router.children,
                        data.path,
                        data.title
                    )
                    if (tempRoutes.length >= 1) {
                        res = [...res, ...tempRoutes]
                    }
                }
            }
            return res
        },
        generateMenus(menus, basePath = '/', prefixTitle = []) {
            let res = []
            for (const menu of menus) {
                const data = {
                    path: path.resolve(basePath, menu.url),
                    title: [...prefixTitle]
                }
                if (menu.title&&menu.viewable) {
                    data.title = [...data.title, menu.title]
                    res.push(data)
                }
                if (menu.children) {
                    const tempMenus = this.generateMenus(
                        menu.children,
                        data.url,
                        data.title
                    )
                    if (tempMenus.length >= 1) {
                        res = [...res, ...tempMenus]
                    }
                }
            }
            return res
        },
        querySearch(query) {
            if (query !== '') {
                this.options = this.fuse.search(query)
                console.log(this.options)
            } else {
                this.options = []
            }
        }
    }
}
</script>

<style lang="less" scoped>
.header-search {
    input::-ms-input-placeholder {
        text-align: center;
    }
    input::-webkit-input-placeholder {
        text-align: center;
    }
    // height: 50px;
    //   font-size: 0 !important;
    position: relative;
    margin-top: 10px;
    color: #fff;
    .icon-sousuo {
        // cursor: pointer;
        top: 46%;
        left: 160px;
        transform: translateY(-50%);
        position: absolute;
        display: inline-block;
        width: 14px;
        height: 14px;
        z-index: 2;
        vertical-align: middle;
        color: #c0c4cc;
    }
    .header-search-select {
        transition: width 0.2s;
        width: 0;
        overflow: hidden;
        background: transparent;
        border-radius: 0;
        display: inline-block;
        vertical-align: middle;
        /deep/ .el-input__inner {
            text-align: center;
            border-radius: 3px;
            height: 40px;
            border: 0;
            // padding-left: 40px;
            padding-right: 0;
            box-shadow: none !important;
            // border: 1px solid rgba(0, 0, 0, 0.1);
            vertical-align: middle;
            background: #e3eaf1;
            border-radius: 20px;
            padding: 0;
        }
    }
    &.show {
        .header-search-select {
            // padding-left: 10px;
            width: 100%;
            //   margin-left: 10px;
        }
    }
}
</style>
