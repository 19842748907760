import axios from 'axios'
import qs from 'qs'
import _ from 'lodash'
import store from '../store'
import router from '../router'
import settings from '../settings'
import { handleError } from './index'
// import { Message } from 'element-ui'
import { loading, customMessage } from './elementUI'
import { downloadExcel } from '@/utils'

const deps = {
    store,
    router,
    settings
}

const axiosInstance = axios.create()

let isOpenLoading = false // 是否开启loading

axiosInstance.interceptors.request.use(
    function (config) {
        isOpenLoading && loading.open()
        return config
    },
    function (error) {
        isOpenLoading && loading.close()
        return Promise.reject(error)
    }
)

axiosInstance.interceptors.response.use(
    function (response, config) {
        isOpenLoading && loading.close()
        // 处理未登录
        if (response.data.code === 401 && !response.config.ignoreCurrent) {
            deps.router.push({
                name: '401',
                query: {
                    redirect: window.location.href
                }
            })
        }
        return response
    },
    function (error) {
        isOpenLoading && loading.close()
        return Promise.reject(error)
    }
)

function rejectHandle (reject, error, throwError) {
    if (throwError) {
        reject(error)
    }
    handleError(error, true)
}

// todo: 待优化
async function request (serverName, path, config) {
    const {
        params = {},
        method = 'POST',
        dataType = 'json',
        customConfig = {},
        ...rest
    } = config
    // 是否开启全局loading
    const {
        openLoading = false,
        throwError = false,
        codeHandlers = false
    } = customConfig
    isOpenLoading = openLoading
    // dataType = 'form',
    // method = 'POST',
    // customConfig.throwError = false,
    // customConfig.codeHandlers = null
    const url = deps.settings.getServer(serverName) + path
    return new Promise((resolve, reject) => {
        const currentUser = store.getters.currentUser
        const authToken = (currentUser && currentUser.id) || ''
        const reqMethod = method && method.toLowerCase()
        let options = {
            headers: {
                authToken
            },
            withCredentials: true,
            url,
            method
        }
        const customOptions = {}
        if (reqMethod === 'get') {
            options = _.merge(options, { params })
        } else {
            const contentType = {
                formdata: 'multipart/form-data',
                form: 'application/x-www-form-urlencoded',
                json: 'application/json'
            }
            // 之后customConfig解构
            const dataTypeVal = dataType && dataType.toLowerCase()
            const computedOptions = {
                data: params,
                headers: { 'Content-Type': contentType[dataTypeVal] }
            }
            if (dataTypeVal === 'form' && typeof params !== 'string') {
                computedOptions.data = qs.stringify(computedOptions.data)
            }
            if (!contentType[dataTypeVal]) {
                delete computedOptions.headers['Content-Type']
            }
            options = _.merge(options, computedOptions)
        }
        options = _.merge(options, rest)
        axiosInstance(options).then(
            response => {
                const data = response.data
                const code = data.code
                if (code === 200) {
                    resolve(data)
                } else if (code === 401) {
                    deps.router.push({
                        name: '401',
                        query: { redirect: window.location.href }
                    })
                } else {
                    let handled = false
                    if (customConfig.codeHandlers != null) {
                        let handler = customConfig.codeHandlers
                        if (typeof customConfig.codeHandlers === 'object') {
                            handler = customConfig.codeHandlers[code]
                        }
                        if (typeof handler === 'function') {
                            handled = true
                            handler(data, code)
                        }
                    }
                    if (!handled) {
                        rejectHandle(reject, data, customConfig.throwError)
                    }
                }
            },
            error => {
                error.code = 500
                error.message = `无法连接到服务器，请检查您的网络[${
                    error.response ? error.response.status : ''
                }]`
                error.data = null
                rejectHandle(reject, error, customConfig.throwError)
            }
        )
    })
}

export default request

export const exportData = async ({ name = '数据导出', path, serverName, params }) => {
    loading.open()
    const url = deps.settings.getServer(serverName) + path
    const currentUser = store.getters.currentUser
    const authToken = (currentUser && currentUser.id) || ''
    const options = {
        method: 'post',
        url,
        // withCredentials: true,
        responseType: 'blob', // 表明返回服务器返回的数据类型
        data: qs.stringify(params),
        headers: {
            authToken,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    try {
        const { data } = await axiosInstance(options)
        const text = await data.text()
        console.log(customMessage().warning)
        // console.log(code)
        if (text.includes('110006')) {
            customMessage().warning('数据导出不能超过50000条')
        } else if (text.includes('500')) {
            customMessage().error('导出失败')
        } else {
            downloadExcel(data, name + '.xlsx')
        }
        return data
    } catch (err) {
        Error(err)
    } finally {
        loading.close()
    }
}

// export const fetch = (path = '', params = {}, restConfig = {
//     method: 'post',
//     'Content-Type': 'application/json'
// }) => request('sellServer', path, {
//     params,
//     ...restConfig
// })
