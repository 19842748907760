import Layout from '@/layouts/default'

const routes = [
    {
        name: 'ToDoMng',
        path: '/todo',
        component: Layout,
        meta: { title: '待办管理', noCache: false, afterCrumb: '待办' },
        children: [
            {
                path: '',
                redirect: 'todo',
            },
            {
                name: 'Todo',
                path: 'todo',
                component: () =>
                    import(
                        /* webpackChunkName: "group-todo" */ './pending/index'
                    ),
                meta: { title: '今日待办', noCache: false },
            },
            {
                name: 'DayOverList',
                path: 'day-over-list',
                component: () =>
                    import(
                        /* webpackChunkName: "group-todo" */ './dayOver/index'
                    ),
                meta: { title: '历史日清', noCache: false },
            },
        ],
    },
]

export default routes
