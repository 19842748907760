import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import settings from '../settings'
import permission from '../utils/permission'
import { tenantView } from '../components/_tenant'

import consoleRoutes from '../modules/console/routes'
import desktopRoutes from '../modules/desktop/routes'
import sellRoutes from '../modules/sell/routes'
import financeRoutes from '../modules/finance/routes'
import profileRoutes from '../modules/profile/routes'
import systemRoutes from '../modules/system/routes'
import oaRoutes from '../modules/oa/routes'
import customerRoutes from '../modules/customer/routes'
import operationRoutes from '../modules/operation/routes'
import todoRoutes from '../modules/todo/routes'
import supplyChainRoutes from '../modules/supplyChain/routes'
import purchaseRoutes from '../modules/purchase/routes'
import excelDocument from '../modules/excelDocument/routes'

const routes = [
    {
        path: '/',
        redirect: '/desktop'
    },
    // 这里添加子模块的路由
    ...consoleRoutes,
    ...desktopRoutes,
    ...sellRoutes,
    ...financeRoutes,
    ...profileRoutes,
    ...systemRoutes,
    ...oaRoutes,
    ...customerRoutes,
    ...operationRoutes,
    ...todoRoutes,
    ...supplyChainRoutes,
    ...purchaseRoutes,
    ...excelDocument,
    {
        // 多租户入口页面
        path: '/tenant/:id',
        name: 'tenant',
        meta: { allow: '*' },
        component: tenantView
    },
    {
        // 配置不存在的地址提示信息
        path: '*',
        redirect: to => {
            return {
                name: '404',
                query: {
                    url: to.fullPath
                }
            }
        }
    }
]

const beforeEach = async function (store, settings, to, from, next) {
    store.dispatch('fxdata/setLoadingStatus', {
        isLoading: true
    })
    if (to.matched && to.matched.length > 0) {
        // 已匹配到路径了
        let allow = to.meta ? to.meta.allow : undefined
        if (!allow && settings) {
            allow = settings.defaultAllow
        }
        const currentUser = store.getters.currentUser
        if (allow && allow !== '*') {
            // if (!currentUser || currentUser.userId <= 0) {
            if (!currentUser || !currentUser.userId) {
                // 用户未登录，则跳转到登录页
                next({
                    name: '401',
                    query: {
                        redirect: window.location.href
                    }
                })
                return
            } else if (allow !== '?' && !permission.hasPermission(allow)) {
                // 判断是否有无访问
                // 无权限访问
                next({
                    name: '403',
                    query: {
                        redirect: window.location.href
                    }
                })
                return
            }
        }
    }
    next()
}

const afterEach = function (store, settings, to) {
    store.dispatch('fxdata/setLoadingStatus', {
        isLoading: false
    })

    let t = to.meta ? to.meta.title : null
    if (!t) {
        t = to.name
    }
    const title = settings ? settings.title : null
    if (t) {
        if (title) {
            t = t + ' - ' + title
        }
    } else {
        t = title
    }
    if (t) {
        window.document.title = t
    }
}

Vue.use(Router)
const createRouter = () =>
    new Router({
        base: settings.basePath,
        mode: 'history',
        routes
    })
const router = createRouter()

router.beforeEach(function (to, from, next) {
    beforeEach(store, settings, to, from, next)
})
router.afterEach(function (to) {
    afterEach(store, settings, to)
})

export function resetRouter () {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}
export default router
