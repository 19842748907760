// 临时传输数据用
const state = {
    customBreadSlot: null // 自定义面包屑插槽
}
const mutations = {
    UPDATE_BREAD_SLOT: (state, val) => {
        // 传递面包屑（如果已调用，离开页面时请将值重置为null）
        state.customBreadSlot = val
    }
}

export default {
    namespaced: true,
    state,
    mutations
}
