<template>
    <div id="tags-view-container" class="tags-view-container">
        <scroll-pane ref="scrollPane" class="tags-view-wrapper" @scroll="handleScroll">
            <router-link
                v-for="tag in visitedViews"
                ref="tag"
                :key="tag.path"
                :class="isActive(tag) ? 'active' : ''"
                :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
                tag="span"
                class="tags-view-item"
                @click.middle.native="!isAffix(tag) ? closeSelectedTag(tag) : ''"
                @contextmenu.prevent.native="openMenu(tag, $event)"
            >
                <i v-if="showDesktopIcon(tag.title)" style="font-size: 14px;" class="iconfont icon-shouye"></i>
                {{ tag.title }}
                <span v-if="!isAffix(tag)" class="iconfont icon-wenbenkuangkuaijiequxiao" @click.prevent.stop="closeSelectedTag(tag)" />
            </router-link>
        </scroll-pane>
        <ul v-show="visible" :style="{ left: left + 'px', top: top + 'px' }" class="contextmenu">
            <li @click="refreshSelectedTag(selectedTag)">刷新</li>
            <li v-if="!isAffix(selectedTag)" @click="closeSelectedTag(selectedTag)">
                关闭
            </li>
            <li @click="closeOthersTags">关闭其他</li>
            <li @click="closeAllTags(selectedTag)">关闭所有</li>
        </ul>
    </div>
</template>

<script>
import ScrollPane from './scrollPane'
import router from '@/router'
import path from 'path'
import permission from '@/utils/permission'
export default {
    components: { ScrollPane },
    data () {
        return {
            visible: false,
            top: 0,
            left: 0,
            selectedTag: {},
            affixTags: [],
            desktops: [
                '工作台',
                '分销工作台',
                'HR工作台',
                '运营工作台',
                '运营管理工作台',
                '培训工作台',
                '财务工作台',
                '采购工作台',
                '供应链工作台',
                '自定义工作台'
            ]
        }
    },
    computed: {
        visitedViews () {
            return this.$store.state.tagsView.visitedViews
        },
        routes () {
            return router.options.routes
        }
    },
    watch: {
        $route () {
            this.addTags()
            this.moveToCurrentTag()
        },
        visible (value) {
            if (value) {
                document.body.addEventListener('click', this.closeMenu)
            } else {
                document.body.removeEventListener('click', this.closeMenu)
            }
        }
    },
    created () { },
    mounted () {
        this.beforeUnload()
        this.initTags()
        this.addTags()
    },
    methods: {
        isActive (route) {
            return route.path === this.$route.path
        },
        isAffix (tag) {
            return tag.meta && tag.meta.affix
        },
        filterAffixTags (routes, basePath = '/') {
            let tags = []
            routes.forEach((route) => {
                // console.log(permission.hasPermission(route.meta.allow));
                // todo:之后只显自定义工作台 route.meta.allow === '*'
                if (route.meta && route.meta.affix && route.meta.allow && (permission.hasPermission(route.meta.allow) || route.meta.allow === '*')) {
                    const tagPath = path.resolve(basePath, route.path)
                    tags.push({
                        fullPath: tagPath,
                        path: tagPath,
                        name: route.name,
                        meta: { ...route.meta }
                    })
                }
                if (route.children) {
                    const tempTags = this.filterAffixTags(route.children, route.path)
                    if (tempTags.length >= 1) {
                        tags = [...tags, ...tempTags]
                    }
                }
            })
            return tags
        },
        initTags () {
            const affixTags = (this.affixTags = this.filterAffixTags(this.routes))
            for (const tag of affixTags) {
                // Must have tag name
                if (tag.name) {
                    this.$store.dispatch('tagsView/addVisitedView', tag)
                }
            }
        },
        addTags () {
            const { name } = this.$route
            if (name) {
                this.$store.dispatch('tagsView/addView', this.$route)
                // console.log(this.visitedViews);
            }
            return false
        },
        moveToCurrentTag () {
            const tags = this.$refs.tag
            this.$nextTick(() => {
                for (const tag of tags) {
                    if (tag.to.path === this.$route.path) {
                        this.$refs.scrollPane.moveToTarget(tag)
                        // when query is different then update
                        if (tag.to.fullPath !== this.$route.fullPath) {
                            this.$store.dispatch('tagsView/updateVisitedView', this.$route)
                        }
                        break
                    }
                }
            })
        },
        refreshSelectedTag (view) {
            this.$store.dispatch('tagsView/delCachedView', view).then(() => {
                const { fullPath } = view
                this.$nextTick(() => {
                    // this.$router.replace({
                    //   path: '/redirect' + fullPath
                    // })
                    this.$router.go(0)
                })
            })
        },
        closeSelectedTag (view) {
            this.$store
                .dispatch('tagsView/delView', view)
                .then(({ visitedViews }) => {
                    if (this.isActive(view)) {
                        this.toLastView(visitedViews, view)
                    }
                })
        },
        closeOthersTags () {
            this.$router.push(this.selectedTag)
            this.$store
                .dispatch('tagsView/delOthersViews', this.selectedTag)
                .then(() => {
                    this.moveToCurrentTag()
                })
        },
        closeAllTags (view) {
            this.$store.dispatch('tagsView/delAllViews').then(({ visitedViews }) => {
                if (this.affixTags.some((tag) => tag.path === view.path)) {
                    return
                }
                this.toLastView(visitedViews, view)
            })
        },
        toLastView (visitedViews, view) {
            const latestView = visitedViews.slice(-1)[0]
            if (latestView) {
                this.$router.push(latestView.fullPath)
            } else {
                // now the default is to redirect to the home page if there is no tags-view,
                // you can adjust it according to your needs.
                if (view.name === 'Dashboard') {
                    // to reload home page
                    this.$router.replace({ path: '/redirect' + view.fullPath })
                } else {
                    this.$router.push('/')
                }
            }
        },
        openMenu (tag, e) {
            const menuMinWidth = 105
            const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
            const offsetWidth = this.$el.offsetWidth // container width
            const maxLeft = offsetWidth - menuMinWidth // left boundary
            const left = e.clientX - offsetLeft + 15 // 15: margin right
            if (left > maxLeft) {
                this.left = maxLeft
            } else {
                this.left = left
            }
            this.top = e.clientY
            this.visible = true
            this.selectedTag = tag
        },
        closeMenu () {
            this.visible = false
        },
        handleScroll () {
            this.closeMenu()
        },
        showDesktopIcon (title) {
            return this.desktops.includes(title)
        },
        beforeUnload () {
            // 监听页面刷新
            window.addEventListener('beforeunload', () => {
                // visitedViews数据结构太复杂无法直接JSON.stringify处理，先转换需要的数据
                let tabViews = this.visitedViews.map((item) => {
                    return {
                        fullPath: item.fullPath,
                        hash: item.hash,
                        meta: { ...item.meta },
                        name: item.name,
                        params: { ...item.params },
                        path: item.path,
                        query: { ...item.query },
                        title: item.title
                    }
                })
                sessionStorage.setItem('tabViews', JSON.stringify(tabViews))
            })
            // 页面初始化加载判断缓存中是否有数据
            let oldViews = JSON.parse(sessionStorage.getItem('tabViews')) || []
            if (oldViews.length > 0) {
                this.$store.state.tagsView.visitedViews = oldViews
            }
        }
    }
}
</script>

<style lang="less" scoped>
@import "../../styles/variables.less";

.tags-view-container {
    flex: 1;
    overflow: auto;
    // height: 34px;
    // width: 100%;
    background: #fff;
    // border-bottom: 1px solid #d8dce5;
    // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
    .tags-view-wrapper {
        .tags-view-item {
            position: relative;
            box-sizing: border-box;
            display: inline-block;
            height: 30px;
            padding: 0 12px;
            margin-top: 4px;
            margin-left: 14px;
            font-size: 12px;
            line-height: 30px;
            color: #a7b6c3;
            // min-width: 120px;
            text-align: center;
            cursor: pointer;
            // color: #495060;
            background: #fff;
            border: 1px solid #edf1f6;
            border-radius: 37px;

            &:first-of-type {
                margin-left: 15px;
            }

            &:last-of-type {
                margin-right: 15px;
            }

            &:hover {
                background-color: #f7f9fc;
            }

            &.active {
                color: #fff;
                background-color: #69acf1;
                border-color: #69acf1;
                // &::before {
                //   content: "";
                //   background: #fff;
                //   display: inline-block;
                //   width: 8px;
                //   height: 8px;
                //   border-radius: 50%;
                //   position: relative;
                //   margin-right: 2px;
                // }
            }

            &.active:hover {
                background-color: #80bbf7;
                border-color: #80bbf7;
            }

            &.active .el-icon-close:hover {
                color: #fff;
                background-color: #9ac7f6;
            }
        }
    }

    .contextmenu {
        position: absolute;
        z-index: 3000;
        padding: 5px 0;
        margin: 0;
        font-size: 12px;
        font-weight: 400;
        color: #333;
        list-style-type: none;
        background: #fff;
        border-radius: 4px;
        box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);

        li {
            padding: 7px 16px;
            margin: 0;
            cursor: pointer;

            &:hover {
                background: #eee;
            }
        }
    }
}
</style>

<style lang="less">
@import "../../styles/variables.less";
//reset element css of el-icon-close
.tags-view-wrapper {
    background-color: @navBarBg;

    .tags-view-item {
        .icon-wenbenkuangkuaijiequxiao {
            font-size: 16px;
            text-align: center;
            vertical-align: -2px;
            border-radius: 50%;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            transform-origin: 100% 50%;

            &::before {
                display: inline-block;
                transform: scale(0.5);
            }

            &:hover {
                color: #fff;
                background-color: #9ac7f6;
            }
        }
    }
}
</style>
