<template>
    <div>
        <div class="gb-menu_mask" @click="hideMenu()" v-if="show">
            <div class="gb-menu_btn">
                <i class="iconfont icon-shouqix"></i>
            </div>
        </div>
        <transition name="el-zoom-in-left">
            <div class="gb-menu" v-if="show">
                <div class="gb-menu-search" style="width:420px">
                    <search @hideMenu="hideMenu()" />
                </div>
                <div class="gb-menu-main">
                    <el-scrollbar
                        :native="false"
                        wrapStyle=""
                        class="menu-scrollbar"
                        wrap-class="menu-scrollbar__wrap"
                        :noresize="false"
                        tag="section"
                    >
                        <menu-item
                            class="menu-item"
                            v-for="menu in menuList"
                            :key="menu.title"
                            :item="menu"
                        />
                    </el-scrollbar>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import search from '@/components/HeaderSearch'
import menuMethods from '@/components/_menu'
import axios from 'axios'
import MenuItem from './menuItem'
export default {
    name: '',
    components: { search, MenuItem },
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isClickBySelf: false,
            menuGroups: [],
            viewableResouces: [],
            menuList: []
        }
    },
    created() {
        this.getResources()
    },
    mounted() {
        this.$bus.$on('menuClick', () => {
            this.hideMenu()
        })
    },
    watch: {
        show(newValue, oldValue) {
            if (newValue) {
                this.getResources()
                console.log(this.menuList, '====menuList')
            }
        }
    },
    methods: {
        ...menuMethods,
        getResources() {
            let dict = axios.getCurrentMenus()
            let menus = Object.values(dict)
            if (
                menus &&
                menus[0] &&
                menus[0].children &&
                menus[0].children.length > 0
            ) {
                this.menuList = menus[0].children
            }
        }
    }
}
</script>
<style lang="less" scoped>
@import '../../styles/variables.less';
.gb-menu {
    position: fixed;
    z-index: @sideBarZIndex;
    top: 60px;
    left: 0;
    box-sizing: border-box;
    width: @sideBarWidth;
    height: 80%;
    margin-top: 20px;
    margin-left: 20px;
    padding: 20px 0 20px 40px;
    border-radius: 0 36px 36px 36px;
    // background: @menuBg;
    background: #fff;
    box-shadow: 0 0 20px 0 #d6dadd;
    &-search {
        height: 50px;
        // padding-left: 40px;
        padding-right: 40px;
    }
    &-main {
        width: 100%;
        height: calc(100% - 50px);
    }
    /deep/ .el-scrollbar.menu-scrollbar {
        width: 100%;
        height: 100%;
        .el-scrollbar__wrap {
            overflow-x: hidden !important;
        }
    }
    &::before {
        position: absolute;
        top: -20px;
        bottom: 0;
        left: 0;
        width: 0;
        height: 0;
        content: '';
        border-right: 20px solid transparent;
        border-bottom: 20px solid #fff;
    }
    &_mask {
        position: absolute;
        // background: transparent;
        z-index: @sideBarZIndex;
        top: 0;
        width: 100%;
        height: 100%;
        background: hsla(0, 3%, 7%, 0.1) border-box;
    }
    &_btn {
        line-height: 50px;
        width: 110px;
        height: 50px;
        cursor: pointer;
        text-align: center;
        background-color: @themeColor;
        border-bottom: 1px solid @themeColor;
        .icon-shouqix {
            font-size: 22px;
            color: #fff;
        }
    }
}
</style>
