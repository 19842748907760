<!--登录等全页面的布局-->
<template>
    <div class="gb-container">
        <Menu ref="sidebar" @clickMenu="triggerMenu" :show="show" />
        <!-- <div class="dialog"> -->
        <NavBar class="gb-navbar fixed-header" @clickMenu="triggerMenu" />
        <AppMain class="gb-main" />
        <!-- </div> -->
        <!-- <div class="copyright">
      <p>技术支持：上海骨贝网络科技有限公司</p>
      <p>&copy; 2019-2021 沪ICP备15028522号. All Rights Reserved. Gubei Shanghai Technology Co. Ltd.</p>
    </div> -->
    </div>
</template>

<script>
import NavBar from '@/components/NavBar'
import Menu from '@/components/Menu'
import AppMain from '@/components/AppMain'
export default {
    components: {
        NavBar,
        Menu,
        AppMain
    },
    data() {
        return {
            show: false
        }
    },
    methods: {
        triggerMenu() {
            this.show = !this.show
        }
    }
}
</script>

<style lang="less" scoped>
@import '../styles/common.less';
.gb {
    &-container {
        .size(100%, 100%);
        .flex_column();
        overflow: hidden;
    }
    &-nav {
        width: 100%;
        transition: width 0.28s;
    }
    &-menu {
        .flex_column();
        overflow: hidden;
        flex: 1;
        box-sizing: border-box;
        background: #fff;
    }
    &-main {
        .flex_column();
        overflow: hidden;
        flex: 1;
        box-sizing: border-box;
        background: #fff;
    }
}
</style>
