import Layout from '@/layouts/console'

const routes = [
    {
        path: '/console',
        name: '控制台',
        meta: { allow: '*' },
        component: Layout,
        children: [
            {
                path: '',
                name: '控制台首页',
                meta: { allow: '?' },
                component: () =>
                    import(/* webpackChunkName: "group-console" */ './index')
            },
            {
                path: 'login',
                name: '401',
                component: () => import('./Login/index'),
                meta: { title: '登录', allow: '*', hidden: true }
            },
            {
                path: '404',
                name: '404',
                meta: { allow: '*' },
                component: () =>
                    import(/* webpackChunkName: "group-console" */ './404')
            },
            {
                path: '403',
                name: '403',
                meta: { allow: '*' },
                component: () =>
                    import(/* webpackChunkName: "group-console" */ './403')
            }
        ]
    }
]

export default routes
