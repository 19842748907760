import Layout from '@/layouts/default'

const routes = [
    {
        path: '/purchase',
        name: 'Purchase',
        component: Layout,
        meta: { title: '采购' },
        children: [
            {
                //配置默认首页
                path: '',
                redirect: 'follow'
            },
            {
                path: 'follow',
                name: 'PurchaseFollow',
                meta: { title: '采购跟进'},
                component: () =>
                    import(
                        /* webpackChunkName: "group-purchase" */ './purchaseFollow/index'
                    )
            },
            {
                path: 'price',
                name: 'PurchasePrice',
                meta: { title: '采购价格' },
                component: () =>
                    import(
                        /* webpackChunkName: "group-purchase" */ './purchasePrice/index'
                    )
            }
        ]
    }
]

export default routes
