import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import https from './utils/https'
import remConfig from './components/_rem' // 配置rem
import permission from './utils/permission'
import tenant from './components/_tenant'
import ElementUI from 'element-ui'
import VueClipboard from 'vue-clipboard2'
import moment from 'moment'
import bus from 'utils/eventBus'
import { Message } from 'element-ui'

import '@/styles/index.less' // global css
// 全局组件
import GbTable from '@/components/Table'
// 定义一个新的Message方法，多传入一个offset参数
const $message = options => {
    return Message({
        ...options,
        offset: 70
    })
};

// 重写一遍success的方法,将offset写入options
['success', 'warning', 'info', 'error'].forEach(type => {
    $message[type] = options => {
        if (typeof options === 'string') {
            options = {
                message: options,
                offset: 70
            }
        }
        options.type = type
        return Message(options)
    }
})
Vue.component('GbTable', GbTable)

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV !== 'production'
remConfig() // 执行rem

Vue.use(ElementUI)
// 将$message挂载到this上
Vue.prototype.$message = $message
Vue.use(permission) // 权限控制指令，给元素加上v-permission
Vue.use(VueClipboard)
Vue.prototype.$moment = moment
Vue.prototype.$axios = https

Vue.prototype.$bus = bus // 用于不同组组件分发事件 $on $emit $off $offAll

;(async () => {
    // 在创建全局Vue实例前，尝试获取当前登录用户（用户后续判断是否登录）
    await https.fetchCurrentUser()
    // 初始化当前租户信息
    await tenant.initTenant()

    new Vue({
        el: '#app',
        router,
        store,
        render: h => h(App)
    }).$mount('#app')
})()
