import Layout from '@/layouts/default'

const routes = [
  {
    name: "Sell",
    path: "/sell",
    component: Layout,
    redirect: "/sell/distribution",
    meta: {
      title: "销售"
    },
    children: [
      {
        name: "DistrbutionLayout",
        path: "distribution",
        component: () => import(/* webpackChunkName: "group-sell" */ "./distribution/layout"),
        meta: { title: "分销", noCache: false },
        children: [
          {
            path: "",
            redirect: "distributor/list"
          },
          /**
           * --------分销商档案路由-----------
           */
          {
            path: "distributor/list",
            component: () => import(/* webpackChunkName: "group-sell" */ "./distribution/distributor/index"),
            name: "DistributionList",
            meta: { title: "分销商档案", noCache: false}
          },
          {
            path: "distributor/add",
            component: () => import(/* webpackChunkName: "group-sell" */  "./distribution/distributor/add/index"),
            name: "DistributionAdd",
            meta: { title: "分销商档案新建",hidden:true  }
          },
          {
            path: "distributor/goods-daily",
            component: () => import(/* webpackChunkName: "group-sell" */  "./distribution/distributor/goods-daily"),
            name: "GoodsDaily",
            meta: { title: "分销产品日报" }
          },
          {
            path: "distributor/daily",
            component: () => import(/* webpackChunkName: "group-sell" */  "./distribution/distributor/daily"),
            name: "GoodsDaily",
            meta: { title: "分销商日报" }
          }
        ]
      }
    ]
  }
];

export default routes;
