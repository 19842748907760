<template>
  <div class="gb-table">
    <el-table ref="el-table" :data="table.data" v-bind="$attrs" v-on="$listeners" @row-click="rowClick" @cell-dblclick="cellDblclick" :height="tableHeight" @selection-change="handleSelectionChange">
      <!-- 序列号/复选框 -->
      <div v-if="table.firstColumn">
        <el-table-column :type="table.firstColumn.type" width="50" :label="table.firstColumn.label" align="center" v-if="table.firstColumn.type !== 'index'"></el-table-column>
      </div>
      <el-table-column minWidth="50" label="序号" align="center">
        <template slot-scope="scope">
          <span>{{ isShowPagination ? (table.currentPage - 1) * table.pageSize + scope.$index + 1 : scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <slot name="begin"></slot>
      <template v-for="(item, index) in columnList">
        <!-- 常规列数据  :show-overflow-tooltip="true" 超长hover显示  -->
        <el-table-column v-if="!item.type" v-bind="$attrs" v-on="$listeners" :key="index" :label="item.label" :min-width="item['min-width'] || item.minWidth || item.width" :prop="item.prop" :align="item.align || 'center'" :show-overflow-tooltip="item.showTip" :fixed="item.fixed">
          <template v-if="item.tip" slot="header">
            {{ item.label }}
            <el-tooltip :content="item.tip" placement="top">
              <i style="margin-left:.05rem;cursor:pointer" class="iconfont icon-shuoming"></i>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <template v-if="item.formatter">
              {{ item.formatter(scope.row) }}
              <!-- </div> -->
            </template>
            <template v-else>
              {{ scope.row[item.prop] }}
            </template>
          </template>
        </el-table-column>
        <el-table-column v-else-if="item.type === 'switch'" v-bind="$attrs" v-on="$listeners" :key="index" :label="item.label" :min-width="item['min-width'] || item.minWidth || item.width" :prop="item.prop" :align="item.align || 'center'" :show-overflow-tooltip="item.showTip">
          <template slot-scope="scope">
            <el-switch v-model="scope.row[item.prop]" :active-text="scope.row[item.prop] ? '启用' : '禁用'">
            </el-switch>
          </template>
        </el-table-column>
      </template>
      <slot></slot>
      <el-table-column v-if="table.operator" :fixed="table.operatorConfig && table.operatorConfig.fixed" :label="(table.operatorConfig && table.operatorConfig.label) || '操作'" :min-width="
          (table.operatorConfig && table.operatorConfig.width) ||
            table.operatorConfig.minWidth ||
            100
        " align="center">
        <template slot-scope="scope">
          <el-button v-for="(item, index) in table.operator" :key="index" @click="item.fun(scope.row)" :type="item.type || 'text'" size="small" v-show="checkIsShow(scope, item)">{{ item.text }}</el-button>
        </template>
      </el-table-column>
      <slot name="end"></slot>
    </el-table>
    <el-pagination v-if="table.data && table.data.length && isShowPagination" :current-page="table.currentPage" @current-change="handlesCurrentChange" @size-change="handlesSizeChange" :page-sizes="[10, 20, 50, 100]" :page-size="table.pageSize" :layout="
        size
          ? 'total, prev, pager, next'
          : 'total, sizes, prev, pager, next, jumper'
      " :total="table.total" v-bind="$attrs" v-on="$listeners"></el-pagination>
    <div v-else class="placeholder">
      <!-- 占位 -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'GbTable',
  props: {
    // table所需数据
    /**
     * @firstColumn: { type: 'selection', isPaging: true },
     * type: 'selection' 表复选框 isPaging: true 表可以跨页勾选
     *
     */
    table: {
      type: Object,
      default: () => {
        return {}
      },
      required: true,
    },
    tableHeight: {
      type: Number,
      default: 600
    },
    // 是否复制单元格
    isCopy: {
      type: Boolean,
      default: false,
    },
    // 是否显示分页
    isShowPagination: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data () {
    return {
      size: '',
      rowData: '', // 点击行的数据
    }
  },
  // watch: {
  //   'table.data': {
  //     immediate: true,
  //     deep: true,
  //     handler(newValue, oldValue) {
  //       console.log(newValue.length);
  //       this.tableHeight = newValue.length > 10 ? '6rem' : null;
  //       console.log(this.tableHeight);
  //     }
  //   }
  // },
  computed: {
    columnList () {
      return this.table.column.filter((item) => {
        return !item.noShowColumn
      })
    },
  },
  activated(){
    this.$nextTick(()=>{
      this.$refs['el-table'].doLayout();
    })
  },
  mounted () {
    this.$nextTick(()=>{
      this.$refs['el-table'].doLayout();
    })
  },
  methods: {
    // 双击复制单元格内容
    cellDblclick (row, column) {
      if (!this.isCopy) {
        return false
      }
      this.$copyText(row[column.property]).then(
        () => {
          this.$message.success('已复制')
        },
        () => {
          this.$message.error('复制失败')
        }
      )
    },
    // 点击行
    rowClick (row) {
      this.rowData = row
      row.selectFlag = !row.selectFlag
      if (row.selectFlag) {
        this.$refs['el-table'].setCurrentRow(row)
      } else {
        this.$refs['el-table'].setCurrentRow()
      }
    }, // 是否显示表格操作按钮
    checkIsShow (scope, item) {
      let isNoShow = false
      if (item.noshow) {
        item.noshow.map((rs) => {
          rs.isShow =
            typeof rs.val === 'string'
              ? rs.val === 'isHadVal'
                ? scope.row[rs.key]
                  ? 'true'
                  : 'false'
                : 'true'
              : rs.val.includes(scope.row[rs.key])
                ? 'false'
                : 'true'
        })
        isNoShow = item.noshow.every((key) => {
          return key.isShow === 'true'
        })
      } else {
        isNoShow = true
      }
      return (
        (!item.show || item.show.val.includes(scope.row[item.show.key])) &&
        isNoShow
      )
    },
    handleSelectionChange (val) {
      this.$emit('handleSelectionChange', val)
    },
    // 当前页码
    handlesCurrentChange (val) {
      console.log(val)
      this.$emit('pageChange', val)
    },
    // pagesize 的变化
    handlesSizeChange (val) {
      this.$emit('sizeChange', val)
    },
  },
}
</script>
<style lang="less" scoped></style>
