const state = {
  entryUrl: null,
}

const mutations = {
  ['setEntryUrl'](state, { entryUrl }) {
    state.entryUrl = entryUrl
  },
}

const actions = {
  setEntryUrl({ commit }, payload) {
    commit('setEntryUrl', payload)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
