const state = {
  isLoading: false,
  isToast: false,
  toastText: null,
}

const mutations = {
  ['setLoadingStatus'](state, payload) {
    state.isLoading = payload.isLoading
  },
  ['setToastStatus'](state, payload) {
    state.isToast = true
    state.toastText = payload.message
  },
}

const actions = {
  setLoadingStatus({ commit }, payload) {
    commit('setLoadingStatus', payload)
  },
  setToastStatus({ commit }, payload) {
    commit('setToastStatus', payload)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
