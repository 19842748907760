<template>
    <!-- 面包屑-当前页面所在的位置 -->
    <div class="gb-breadcrumb">
        <el-breadcrumb class="gb-breadcrumb-items" separator="/">
            <el-breadcrumb-item
                v-for="(item, index) in levelList"
                :key="item.path"
            >
                <span
                    v-if="
                        item.redirect === 'noRedirect' ||
                            index == levelList.length - 1
                    "
                    class="no-redirect"
                    >{{ item.title }}</span
                >
                <!-- <a v-else @click.prevent="gotoPage(item)">{{ item.meta.title }}</a> -->
                <a v-else>{{ item.title }}</a>
            </el-breadcrumb-item>
        </el-breadcrumb>
        <slot name="custom-bread-slot"><!-- 自定义区域 --></slot>
    </div>
</template>

<script>
import { getType } from 'utils/util'
import pathToRegexp from 'path-to-regexp'
import menuMethods from '@/components/_menu'
export default {
    data() {
        return {
            levelList: null
        }
    },
    watch: {
        $route() {
            this.getBreadcrumb()
        }
    },
    created() {
        this.getBreadcrumb()
    },
    methods: {
        getBreadcrumb() {
            console.log('matched', this.$route.matched)
            console.log(this.$route)
            if(this.$route.meta.customCrumbs) {
                // 如果有自定义报表直接返回面包屑
                this.levelList = (this.$route.meta.customCrumbs+'/'+this.$route.meta.title).split('/').map(item => {
                    return {
                        title: item,
                        path: item
                    }
                })
                return
            }
            this.levelList = this.$route.matched.reduce((pre, cur) => {
                const meta = cur.meta
                if (meta && meta.title && meta.breadcrumb !== false) {
                    const { path, meta, redirect } = cur
                    pre.push({ path, redirect, ...meta }) // 解构重组数组
                    ;['beforeCrumb', 'afterCrumb'].forEach(item => {
                        // 前后有自定义面包屑的时候插入
                        // 参考beforeCrumb = [?'title', ?'path'] or '?title, ?path' or {?title: '', ?path:''}
                        // 待优化
                        const current = meta[item]
                        if (current) {
                            let target = {}
                            const typeName = getType(current)
                            if (['String', 'Array'].includes(typeName)) {
                                const val =
                                    typeName === 'Array'
                                        ? current
                                        : current.split(',')
                                const [title, path] = val
                                target = { title, path }
                            } else if (typeName === 'Object') {
                                const { title, path } = current
                                target = { title, path }
                            }
                            if (
                                !pre.find(item => item.title === target.title)
                            ) {
                                // 重复名称的不再添加
                                if(item === 'beforeCrumb') {
                                    // 会替换掉之前已有的屑
                                    if(pre.length - 2 >= 0) {
                                        pre[pre.length - 2] = target
                                    } else {
                                        pre.unshift(target)
                                    }
                                } else {
                                    pre.push(target)
                                }
                            }
                        }
                    })
                }
                return pre
            }, [])
        },
        isDeskTop(route) {
            const name = route && route.name
            if (!name) {
                return false
            }
            return (
                name.trim().toLocaleLowerCase() ===
                'desktop'.toLocaleLowerCase()
            )
        },
        pathCompile(path) {
            // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
            const { params } = this.$route
            var toPath = pathToRegexp.compile(path)
            return toPath(params)
        },
        ...menuMethods,
        handleLink(item) {
            const { redirect, path } = item
            console.log(path, redirect)
            if (redirect) {
                this.$router.push(redirect)
                return
            }
            // this.$router.push(this.pathCompile(path));
            this.$router.push(path)
        }
    }
}
</script>

<style lang="less" scoped>
.gb-breadcrumb {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 37px;
    .el-breadcrumb {
        display: inline-block;
        font-size: 14px;
        line-height: 50px;
        /deep/.el-breadcrumb__inner {
            a:hover {
                color: #9ac7f6;
            }
        }
        .no-redirect {
            color: #97a8be;
            cursor: text;
        }
    }
}
</style>
