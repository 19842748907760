import axios from '@/utils/https'
import request from '@/utils/request'

const fetch = (path = '', params = {}, restConfig = {
    method: 'post',
    'Content-Type': 'application/json'
}) => request('userServer', path, {
    params,
    ...restConfig
})

export default {
    login (query, errorHandle) {
        return axios.fetch('userServer', '/user/login', query, 'form', 'POST', false, errorHandle)
    },
    logout () {
        return axios.fetch('userServer', '/user/logout')
    },
    getRoles () {
        return axios.fetch('userServer', '/permission/getRoles')
    },
    getAdminUsers () {
        return axios.fetch('userServer', '/permission/getAdminUsers')
    },
    getResources () {
        return axios.fetch('userServer', '/permission/getResources')
    },
    // 登录忘记密码发送验证码
    getValidationCode (param) {
        return fetch('/user/sms/validationCode', param, {
            dataType: 'form'
        })
    },
    // 通过短信验证码进行密码找回（未登录状态）
    findPassword (param) {
        return fetch('/user/sms/password/lost', param, {
            dataType: 'form'
        })
    }
}
