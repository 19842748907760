// 菜单模块内部使用
import permission from '@/utils/permission'

const gotoPage = function (item, vue, replace) {
    vue = vue || this
    let url = item.url
    if (!url) {
        vue.$message.info('【' + item.title + '】尚未开放，敬请期待')
        console.log(item.title + ' 未配置Url')
        return false
    }
    vue.$emit('clickMenu', false)
    let currentPath = vue.$route.path.toLowerCase()
    if (currentPath == url.toLowerCase()) {
        console.info('当前路由一致，不可进行跳转')
        return false
    }
    if (!url.startsWith('//') && url.startsWith('/')) {
        let result = replace ? vue.$router.replace(url) : vue.$router.push(url)
        result.catch(e => {
            console.info('当前路由一致，不可进行跳转：' + e.message)
        })
    } else {
    // 处理打开新窗口的菜单
        if (url.startsWith('^')) {
            url = url.substr(1)
            window.open(url)
        } else if (url.startsWith('~')) { // 处理不是本项目的地址，但处在同一个域名下
            url = url.substr(1)
            window.location.href = url
        } else {
            window.location.href = url
        }
    }
    return true
}

const hideMenu = function (vue) {
    vue = vue || this
    vue.$emit('clickMenu', false)
}

const gotoDesktop = function (vue, replace) {
    let item
    if (permission.hasPermission('gbei.desktop.manager')) {
        item = {
            title: '运营管理工作台',
            url: '/desktop/operator-manager'
        }
    } else if (permission.hasPermission('gbei.desktop.operator')) {
        item = {
            title: '运营工作台',
            url: '/desktop/operator'
        }
    } else if (permission.hasPermission('gbei.desktop.sells')) {
        item = {
            title: '分销工作台',
            url: '/desktop/distributor'
        }
    } else if (permission.hasPermission('gbei.desktop.learning')) {
        item = {
            title: '培训工作台',
            url: '/desktop/learning'
        }
    } else if (permission.hasPermission('gbei.desktop.finance')) {
        item = {
            title: '财务工作台',
            url: '/desktop/finance'
        }
    } else if (permission.hasPermission('gbei.desktop.purchase')) {
        item = {
            title: '采购工作台',
            url: '/desktop/purchase'
        }
    } else if (permission.hasPermission('gbei.desktop.supplyChain')) {
        item = {
            title: '供应链工作台',
            url: '/desktop/supply-chain'
        }
    } else {
        item = {
            title: '工作台',
            url: '/desktop/default'
        }
    }
    console.log(item)
    vue = vue || this
    gotoPage(item, vue, replace)
}

export default {
    gotoPage,
    hideMenu,
    gotoDesktop
}
