module.exports = {
    basePath: '/', // 项目部署的目录
    servers: {
    // 服务器地址列表
        commonServer: '/common-api', // 公共服务
        userServer: '/user-api', // 用户服务
        productServer: '/product-api', // 产品服务
        distributionServer: '/distribution-api', // 分销商服务
        accountingServer: '/accounting-api', // 管理会计服务
        oaServer: '/oa-api', // OA服务
        learningServer: '/learning-api', // 培训服务
        sellServer: '/selling-api', // 销售2C服务
        hrbpServer: '/hrbp-api', // HRBP服务
        todoServer: '/todo-api', // 待办服务
        financeServer: '/finance-api', // 财务服务
        tradeServer: '/trade-api', // 交易服务
        documentServer: '/sheet-api', // 文档服务
        // mqttServer: 'wss://${window.location.host}/mqtt' // MQTT服务
        mqttServer: `wss://${window.location.host}/mqtt` // MQTT服务地址

    }
}
