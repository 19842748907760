// 销售toC
const state = {
    platformCategory: null, // 平台品类
    allDeps: null,
    allChildDeps: null,
    partnerInfo: null, // 渠道主体
    productBrands: null, // 品牌
    emlpoyeesList: null, // 员工
    parentCategory: null, // 所有品类
    channels: null,
    channelsCompeting: null,
    channelsNotCompeting: null,
    spuList: null, //
    actions: null, // 动作库
    budgetList: null // 收支项目
}
const mutations = {
    UPDATE_PLATFORM_CATEGORY: (state, val) => {
        // 传递面包屑（如果已调用，离开页面时请将值重置为null）
        state.platformCategory = val
    },
    UPDATE_ALL_DEPTS: (state, val) => {
        // 传递面包屑（如果已调用，离开页面时请将值重置为null）
        state.allDeps = val
    },
    UPDATE_PARTNER_INFO: (state, val) => {
        // 传递面包屑（如果已调用，离开页面时请将值重置为null）
        state.partnerInfo = val
    },
    UPDATE_PRODUCT_BRANDS: (state, val) => {
        // 传递面包屑（如果已调用，离开页面时请将值重置为null）
        state.productBrands = val
    },
    UPDATE_EMLPOYEES_LIST: (state, val) => {
        // 传递面包屑（如果已调用，离开页面时请将值重置为null）
        state.emlpoyeesList = val
    },
    UPDATE_PARENT_CATEGORY: (state, val) => {
        state.parentCategory = val
    },
    UPDATE_CHANNELS: (state, val) => {
        state.channels = val
    },
    UPDATE_CHANNELS_COMPETING: (state, val) => {
        state.channelsCompeting = val
    },
    UPDATE_CHANNELS_NOT_COMPETING: (state, val) => {
        state.channelsNotCompeting = val
    },
    UPDATE_SPULIST: (state, val) => {
        state.spuList = val
    },
    UPDATE_ACTIONS: (state, val) => {
        state.actions = val
    },
    UPDATE_BUDGET_LIST: (state, val) => {
        state.budgetList = val
    },
    UPDATE_ALL_CHILD_DEPS: (state, val) => {
        state.allChildDeps = val
    }
}

export default {
    namespaced: true,
    state,
    mutations
}
