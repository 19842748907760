import Layout from '@/layouts/default';

const Center = () => import('./index');
const Score = () => import('./score');
const ScoreMng = () => import('./score-manage');
const PracticeMng = () => import('./practiceMng');

const routes = [
  {
    path: '/profile',
    component: Layout,
    meta:{ title:'ehr'},
    children: [
      {
        path: '',
        redirect: 'index',
      },
      {
        path: 'index',
        component: Center,
        name: 'Profile',
        meta: { title: '个人中心', noCache: true, breadcrumb: false },
      },
      {
        path: 'score',
        component: Score,
        name: 'Score',
        meta: { title: '我的积分', noCache: true, breadcrumb: true },
      },
      {
        path: 'score-manage',
        component: ScoreMng,
        name: 'Score',
        meta: {
          title: '积分管理',
          noCache: true,
          breadcrumb: true,
          allow: 'gbei.desktop.learning',
        },
      },
      // {
      //   path: 'practice-manage',
      //   component: PracticeMng,
      //   name: 'PracticeMng',
      //   meta: {
      //     title: '培训管理',
      //     noCache: true,
      //     breadcrumb: true,
      //     allow: 'gbei.desktop.learning',
      //   },
      // },
    ],
  },
];

export default routes;
