<template>
    <div>
        <div
            v-if="item.viewable"
            class="menu-item-title"
            @click="item.level === 3 ? goPage(item) : null"
        >
            {{ item.title }}
        </div>
        <template v-if="item.viewable">
          <menuItem
              v-for="child in item.children"
              :key="child.title"
              :item="child"
              :class="{ 'menu-item-nest': true }"
          />
        </template>
    </div>
</template>

<script>
import menuMethods from '@/components/_menu'
export default {
    name: 'menuItem',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {}
    },
    computed: {
        isLast() {
            return this.item.children === undefined
        }
    },
    methods: {
        ...menuMethods,
        goPage(item){
          this.gotoPage(item)
          this.$bus.$emit('menuClick')
        }
    }
}
</script>
<style lang="less" scoped>
.menu-item {
    margin-top: 30px;
    // 一层
    &-title {
        line-height: 24px;
        font-size: 18px;
        color: #303133;
    }
    &-nest {
        // margin-top: 10px;
        // 二层
        .menu-item-title {
            // margin-top: 15px;
            color: #3595e1;
            font-size: 16px;
            line-height: 44px;
        }
        .menu-item-nest {
            // padding-right: 12px;
            display: inline-block;
            // 三层
            .menu-item-title {
                display: inline-block;
                color: #606266;
                font-weight: 500;
                cursor: pointer;
                font-size: 14px;
                margin-right: 30px;
                margin-top: 0;
                line-height: 24px;
            }
            .menu-item-title:hover {
                color: #909399;
            }
        }
    }
}
</style>
