import Layout from '@/layouts/default'

const routes = [
    {
        path: '/supply-chain',
        name: 'SupplyChain',
        component: Layout,
        meta: { title: '供应链' },
        children: [
            {
                //配置默认首页
                path: '',
                redirect: 'daily-report'
            },
            {
                path: 'daily-report',
                name: 'SupplyChainDailyReport',
                meta: { title: '供应链日报' },
                component: () =>
                    import(
                        /* webpackChunkName: "group-supplyChain" */ './dailyReport/index'
                    )
            }
        ]
    }
]

export default routes
