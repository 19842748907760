import ElementUI from 'element-ui'
import { Loading, Message } from 'element-ui'

export const loading = {
    target: null,
    open: function () {
        this.target = Loading.service({ fullscreen: true })
    },
    close: function () {
        this.target.close()
    }
}

export function customMessage () {
    const $message = options => {
        return Message({
            ...options,
            offset: 70
        })
    }

    // 重写一遍success的方法,将offset写入options
    ;['success', 'warning', 'info', 'error'].forEach(type => {
        $message[type] = options => {
            if (typeof options === 'string') {
                options = {
                    message: options,
                    offset: 70
                }
            }
            options.type = type
            return Message(options)
        }
    })
    console.log($message)
    return $message
}

const customUI = {
    $message: customMessage
}

export function elementUIInit (vue) {
    vue.use(ElementUI)
    Object.keys(customUI).forEach(item => {
        vue.prototype[item] = customUI[item]
    })
}
