// 运营数据
const state = {
    fields: [],
    source: [],
    selectedPlatformId: '',
    sourceItem: null,
    channelId: null,
    indicato: null
}
const mutations = {
    UPDATE_FIELDS: (state, val) => {
        // 传递面包屑（如果已调用，离开页面时请将值重置为null）
        state.fields = val
    },
    UPDATE_SOURCE: (state, val) => {
        // 传递面包屑（如果已调用，离开页面时请将值重置为null）
        state.source = val
    },
    UPDATE_SELECTED_PLATFORM_ID: (state, val) => {
        state.selectedPlatformId = val
    },
    UPDATE_SOURCE_ITEM (state, val) {
        state.sourceItem = val
    },
    UPDATE_CHANNEL_ID (state, val) {
        state.channelId = val
    },
    UPDATE_INDICATO () {
        state.indicato = val
    }
}

export default {
    namespaced: true,
    state,
    mutations
}
