function resizeHtml () {
    // 首先取得当前窗口宽度
    /* var width = window.screen.width; */
    var width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
    console.log('width:', width)
    var devicePixelRatio = window.devicePixelRatio
    console.log('devicePixelRatio:', devicePixelRatio)
    var coefficient = 1920 / (devicePixelRatio * width)
    var scaleSize = 100
    // 获得的宽度除以（设计尺寸除以缩放尺寸）
    // var size = (width / (screenWidth / scaleSize))
    var size = scaleSize / coefficient / devicePixelRatio
    console.log(size, '======size')
    // 设置font-size
    document.getElementsByTagName('html')[0].style.fontSize = size + 'px'
}

export default function () {
    resizeHtml()
    window.addEventListener('resize', function () {
        resizeHtml()
    })
}
