import Layout from '@/layouts/default'

const routes = [
    {
        name: 'Finance',
        path: '/finance',
        component: Layout,
        redirect: '/finance/accounting',
        meta: {
            title: '财务'
        },
        children: [
            // 1.管理会计路由
            {
                name: 'MALayout',
                path: 'accounting',
                component: () => import(/* webpackChunkName: "group-finance" */ './accounting/layout'),
                meta: { title: '管理会计', noCache: false },
                children: [
                    {
                        path: '',
                        redirect: 'actual/list'
                    },
                    /**
                     * -------a.数据补录路由-----
                     */
                    {
                        path: 'actual/list',
                        component: () => import(/* webpackChunkName: "group-finance" */ './accounting/dataReLog/index'),
                        name: 'DistributionList',
                        meta: { title: '数据补录', noCache: false }
                    },
                    /**
                     * -------b.日常报表-----
                     */
                    {
                        path: 'daily/list',
                        // component: () => import(/* webpackChunkName: "group-finance" */ "./accounting/daily/index"),
                        component: () => import(/* webpackChunkName: "group-finance" */ './accounting/reportForm/index'),
                        name: 'AccountingDaily',
                        meta: { title: '日常报表', noCache: false }
                    },
                    {
                        path: 'daily/detail',
                        // component: () => import(/* webpackChunkName: "group-finance" */ "./accounting/daily/detail"),
                        component: () => import(/* webpackChunkName: "group-finance" */ './accounting/reportForm/detail'),
                        name: 'DailyReportDetail',
                        meta: { title: '日常报表详情', noCache: false }
                    },
                    /**
                     * -------c.收支项目-----
                     */
                    {
                        path: 'budget-projects',
                        component: () => import(/* webpackChunkName: "group-finance" */ './accounting/budgetProjects'),
                        name: 'BudgetProjects',
                        meta: { title: '收支项目', noCache: false, allow: 'gbei.finance.accounting.budget-projects' }
                    },
                    {
                        path: 'budget-setting',
                        component: () => import(/* webpackChunkName: "group-finance" */ './accounting/budgetSetting'),
                        name: 'BudgetSetting',
                        meta: { title: '收支项目设置', noCache: false, allow: 'gbei.finance.accounting.budget-setting' }
                    },
                    /**
                     * -------d.预算填报-----
                     */
                    {
                        path: 'budget-fill',
                        component: () => import(/* webpackChunkName: "group-finance" */ './accounting/budgetFill'),
                        name: 'BudgetFill',
                        meta: { title: '预算填报', noCache: false }
                    },
                    {
                        path: 'budget-fill/detail',
                        component: () => import(/* webpackChunkName: "group-finance" */ './accounting/budgetFill/detail'),
                        name: 'BudgetFillDetail',
                        meta: { title: '预算填报详情', noCache: false }
                    },
                    /**
                     * -------规划填报-----
                     */
                    {
                        path: 'planning-fill',
                        component: () => import(/* webpackChunkName: "group-finance" */ './accounting/planningFill'),
                        name: 'PlanningFill',
                        meta: { title: '规划填报', noCache: false }
                    },
                    {
                        path: 'planning-report',
                        component: () => import(/* webpackChunkName: "group-finance" */ './accounting/planningFill/report'),
                        name: 'PlanningFillReport',
                        meta: { title: '规划报表', noCache: false }
                    },
                    {
                        path: 'planning-report/detail',
                        component: () => import(/* webpackChunkName: "group-finance" */ './accounting/planningFill/detail'),
                        name: 'PlanningFillDetail',
                        meta: { title: '规划报表详情', noCache: true }
                    },
                    /**
                     * -------e.实际报表-----
                     */
                    {
                        path: 'actual-report/list',
                        // component: () => import(/* webpackChunkName: "group-finance" */ "./accounting/actualReport/index"),
                        component: () => import(/* webpackChunkName: "group-finance" */ './accounting/reportForm/index'),
                        name: 'AccountingDaily',
                        meta: { title: '实际报表', noCache: false }
                    },
                    {
                        path: 'actual-report/detail',
                        //  component: () => import(/* webpackChunkName: "group-finance" */ "./accounting/actualReport/detail"),
                        component: () => import(/* webpackChunkName: "group-finance" */ './accounting/reportForm/detail'),
                        name: 'DailyReportDetail',
                        meta: { title: '实际报表详情', noCache: true }
                    },
                    /**
                     * -------a.系数维护路由-----
                     */
                    {
                        path: 'coefficient/list',
                        component: () => import('./accounting/coefficient/index'),
                        name: 'CoefficientList',
                        meta: { title: '系数维护', noCache: false }
                    },
                    /**
                     * -------数据追踪-----
                     */
                    {
                        path: 'data-tracking/order',
                        component: () => import(/* webpackChunkName: "group-finance" */ './accounting/dataTracking/order/index'),
                        name: 'ActualReport',
                        meta: { title: '订单明细', noCache: false, customCrumbs: '销售/基础' }
                    },
                    {
                        path: 'data-tracking/bill',
                        component: () => import(/* webpackChunkName: "group-finance" */ './accounting/dataTracking/bill/index'),
                        name: 'DailyReportDetail',
                        meta: { title: '账单明细', noCache: false, customCrumbs: '销售/基础' }
                    },
                    /**
                     * -------管会计算日志记录-----
                     */
                    {
                        path: 'accounting-log',
                        component: () => import('./accounting/accountingLog'),
                        name: 'AccountingLog',
                        meta: { title: '计算日志', noCache: false }
                    },
                    /* 管理会计设置 */
                    {
                        path: 'setting',
                        component: () => import(/* webpackChunkName: "group-finance" */ './accounting/setting/index'),
                        name: 'AccountSetting',
                        meta: { title: '管理会计设置', noCache: false }
                    }
                ]
            }
            // 2.。。。。。
        ]
    }
]

export default routes
