import { Message } from 'element-ui'
import axios from 'axios'
import _ from 'lodash'

export function handleError (e, show) {
    console.info(e)
    alertError(e.message)
}

export function alertError (message) {
    Message({
        message: message,
        type: 'error',
        offset: 70
    })
}

export function toastError (message) {
    Message({
        message: message,
        type: 'error',
        offset: 70
    })
}

export function toastSuccess (message) {
    Message({
        message: message,
        type: 'success',
        offset: 70
    })
}

export function isWeixin () {
    const ua = window.navigator.userAgent
    return /MicroMessenger/i.test(ua)
}

export function isiOS () {
    const ua = window.navigator.userAgent
    return /\(i[^;]+;( U;)? CPU.+Mac OS X/i.test(ua)
}
// 格式钱1,111.00
/**
 *
 * @param {金额} money
 * @param {是否需要单位 默认不传} hasUnit
 * @returns 相应格式的金额
 */
export function moneyFilter (money, hasUnit, numberLength = 8) {
    if (
        money &&
        money !== null &&
        money !== '' &&
        money !== undefined &&
        money !== '*****'
    ) {
        money = (money - 0).toFixed(2)
        var length = String(parseInt(Math.abs(money))).length
        money = String(money)
        var left = money.split('.')[0]
        var right = money.split('.')[1]
        var temp = left
            .split('')
            .reverse()
            .join('')
            .match(/(\d{1,3})/g)
        if (!hasUnit) {
            right = right
                ? right.length >= 2
                    ? '.' + right.substr(0, 2)
                    : '.' + right + '0'
                : '.00'
            return (
                (Number(money) < 0 ? '-' : '') +
                temp
                    .join(',')
                    .split('')
                    .reverse()
                    .join('') +
                right
            )
        } else {
            if (length < numberLength) {
                right = right
                    ? right.length >= 2
                        ? '.' + right.substr(0, 2)
                        : '.' + right + '0'
                    : '.00'
                return (
                    (Number(money) < 0 ? '-' : '') +
                    temp
                        .join(',')
                        .split('')
                        .reverse()
                        .join('') +
                    right
                )
            } else if (length <= 8 && length >= numberLength) {
                return Number(left / 10000).toFixed(2) + '万'
            } else {
                return Number(left / 100000000).toFixed(2) + '亿'
            }
        }
    } else if (money === 0) {
        // 注意===在这里的使用，如果传入的money为0,if中会将其判定为boolean类型，故而要另外做===判断
        return '0.00'
    } else {
        return money || ''
    }
}
// downLoad Excel
export function downloadExcel (content, fileName) {
    console.log('content:', content)
    const blob = new Blob([content], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob)
    } else {
        var link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName
        link.click()
        // 释放内存
        window.URL.revokeObjectURL(link.href)
    }
}

/**
 *
 * @param {Array} data 原始数据 必填
 * @param {String} pidFiled 父级字段名 必填
 * @param {String} valueFiled 唯一值字段名 必填
 * @param {String} labelFiled 显示名称字段名 非必填
 * @returns
 */
export function makeDataToTree (value, pidFiled, valueFiled, labelFiled) {
    const map = {}
    const val = []
    if (!value || !(value instanceof Array)) return false
    const data = _.cloneDeep(value)
    if (data.length > 0) {
        data.forEach(item => {
            if (labelFiled) {
                item.value = item[valueFiled]
                item.label = item[labelFiled]
            }
            map[item[valueFiled]] = item
        })
        data.forEach(item => {
            const parent = map[item[pidFiled]]
            if (parent) {
                (parent.children || (parent.children = [])).push(item)
            } else {
                val.push(item)
            }
        })
    }
    return val
}
// 树形数据转化
export function transformTree (list, pidFiled, valueFiled) {
    const tree = []
    for (let i = 0, len = list.length; i < len; i++) {
        const item = list[i]
        const pId = item[pidFiled]
        const res = tree.filter(item => item[valueFiled] === pId)
        if (res.length > 0) {
            if (!res[0].children) {
                res[0].children = []
            }
            res[0].children.push(item)
        } else {
            tree.push(item)
        }
    }
    // console.log(tree);
    return tree
}

// 财务、供应链、采购工作台数据处理
export function makeDataToMap (dataArr, titleField, ...rest) {
    let arr = []
    if (!dataArr || !(dataArr instanceof Array)) return arr
    arr = dataArr.map((item, index) => {
        let obj = {}
        obj.title = item[titleField]
        obj.id = item.id ? item.id : null
        rest.forEach((r, rIndex) => {
            obj[`value${rIndex + 1}`] = r.isMoney
                ? '￥' + moneyFilter(item[r.valueField], r.hasUnit, r.unitLength || 5)
                : item[r.valueField]
        })
        return obj
    })
    return arr
}
// 财务、供应链、采购工作台数据处理
export function assembleStatisticalData (dataObj, ...rest) {
    let arr = []
    rest.forEach(r => {
        let obj = {}
        obj.title = r.title
        obj.value = r.isMoney
            ? '￥' + moneyFilter(dataObj[r.valueField] || 0, r.hasUnit, r.unitLength || 5)
            : dataObj[r.valueField]

        arr.push(obj)
    })
    return arr
}

export default {
    handleError,
    alertError,
    toastError,
    toastSuccess,
    isWeixin,
    isiOS,
    downloadExcel,
    moneyFilter,
    makeDataToTree
}
