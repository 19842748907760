<template>
    <div class="navbar">
        <hamburger class="hamburger-container" @showMenu="showMenu" />
        <!-- <breadcrumb class="breadcrumb-container" /> -->
        <TagsView />
        <div class="right-menu">
            <!-- <search id="header-search" class="right-menu-item" /> -->
            <!-- <el-dropdown class="right-menu-item message-container" trigger="click">
        <div class="message-wraper">
          <el-badge v-if="0" :value="9" :max="99" class="item">
            <img src="../../assets/images/notice.png" alt="" title="消息通知" />
          </el-badge>
          <img v-else src="../../assets/images/notice.png" alt="" title="消息通知" />
          <el-dropdown-menu slot="dropdown" class="message-dropdown">
            <el-dropdown-item>暂无内容</el-dropdown-item>
          </el-dropdown-menu>
        </div>
      </el-dropdown>
      <el-dropdown class="right-menu-item pendding-container" trigger="click">
        <div class="pendding-wraper">
          <img src="../../assets/images/pending.png" alt="" title="待办" />
          <el-dropdown-menu slot="dropdown" class="pendding-dropdown">
            <el-dropdown-item>暂无内容</el-dropdown-item>
          </el-dropdown-menu>
        </div>
      </el-dropdown>
      <el-dropdown class="right-menu-item file-container" trigger="click">
        <div class="file-wraper">
          <img src="../../assets/images/file.png" alt="" title="文件管理" />
          <el-dropdown-menu slot="dropdown" class="file-dropdown">
            <el-dropdown-item>暂无内容</el-dropdown-item>
          </el-dropdown-menu>
        </div>
      </el-dropdown> -->
            <!-- <el-dropdown class="avatar-container" trigger="click"> -->
            <div class="avatar-container">
                <div class="avatar-wrapper">
                    <template v-if="currentUser.avatarUrl">
                        <img
                            :src="currentUser.avatarUrl"
                            class="user-avatar"
                            @click="$router.push('/profile/index')"
                        />
                    </template>
                    <img
                        v-else
                        src="../../assets/images/avart.png"
                        class="user-avatar"
                        @click="$router.push('/profile/index')"
                    />
                    <!-- <img v-else src="../../assets/images/avart.jpg" class="user-avatar" /> -->
                    <!-- <i class="iconfont icon-morentouxiang user-avatar"></i> -->
                    <span
                        class="displayName"
                        @click="$router.push('/profile/index')"
                    >{{ currentUser.displayName }}</span>
                    <!-- <i class="el-icon-caret-bottom" /> -->
                    <!-- <i class="iconfont icon-xiala"></i> -->
                    <!-- <img class="close" @click="logout" src="../../assets/images/close.png" alt="" /> -->
                    <i class="iconfont icon-h" @click.prevent="logout"></i>
                </div>
            </div>
            <!-- <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <router-link to="/">
            <el-dropdown-item> 工作台 </el-dropdown-item>
          </router-link>
          <router-link to="/profile/index">
            <el-dropdown-item> 个人中心 </el-dropdown-item>
          </router-link>
          <el-dropdown-item divided @click.native="logout">
            <span style="display: block">退出</span>
          </el-dropdown-item>
        </el-dropdown-menu> -->
            <!-- </el-dropdown> -->
        </div>
    </div>
</template>

<script>
import Hamburger from '@/components/Hamburger'
import TagsView from '@/components/TagsView/index'
export default {
    components: {
        // Breadcrumb,
        Hamburger,
        // search,
        TagsView
    },
    data () {
        return {
            currentUser: {}
        }
    },
    created () {
        this.currentUser = this.$store.getters.currentUser
    },
    methods: {
        showMenu () {
            this.$emit('clickMenu')
        },
        logout () {
            // TODO 与login.vue优化
            this.$confirm('确认要退出系统吗?', '', { type: 'warning' })
                .then(async () => {
                    await this.$store.dispatch('user/logout')
                    this.$router.push(`/console/login?redirect=${this.$route.fullPath}`)
                })
                .catch(() => {})
        }
    }
}
</script>

<style lang="less" scoped>
@import '../../styles/variables.less';

.navbar {
    position: relative;
    display: flex;
    height: @navBarHeight;
    background: @navBarBg;
    border-bottom: 1px solid #eee;
    // overflow: hidden;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

    .hamburger-container {
        float: left;
        height: 100%;
        line-height: 46px;
        // cursor: pointer;
        transition: background 0.3s;
        -webkit-tap-highlight-color: transparent;

        &:hover {
            background: rgba(0, 0, 0, 0.025);
        }
    }

    .breadcrumb-container {
        float: left;
    }

    .right-menu {
        display: flex;
        align-items: center;
        float: right;
        height: 100%;
        // line-height: 50px;
        &:focus {
            outline: none;
        }

        .el-dropdown + .el-dropdown {
            margin-left: 50px;
        }

        .right-menu-item {
            cursor: pointer;

            img {
                width: 20px;
                height: 20px;
            }
        }

        .message-wraper {
            /deep/ .el-badge.item {
                .el-badge__content.is-fixed {
                    transform: translateY(-50%) translateX(100%) scale(0.7);
                }
            }
        }

        .avatar-container {
            height: 100%;
            margin-left: 50px;
            font-size: 14px;
            cursor: pointer;

            .avatar-wrapper {
                position: relative;
                display: flex;
                align-items: center;
                height: 100%;
                color: #606266;

                .user-avatar {
                    width: 32px;
                    height: 32px;
                    margin-right: 10px;
                    border-radius: 10px;
                }

                .icon-xiala {
                    position: absolute;
                    right: -20px;
                    // top: 25px;
                    transform: scale(0.5);
                    // color: #fff;
                }

                .displayName {
                    padding-right: 12px;
                    border-right: 1px solid #eee;
                    // text-decoration: underline;
                }

                .icon-h {
                    margin: 0 18px;
                    font-size: 16px;
                    color: #c0c4cc;
                    // width: 20px;
                    // height: 20px;
                }
            }
        }
    }
}
</style>
