<template>
    <div>
        <breadcrumb
            v-if="breadVisible"
            class="breadcrumb-container"
            style="height: 50px;"
        >
            <template v-if="customBreadSlot" #custom-bread-slot>
                <component :is="customBreadSlot"></component>
            </template>
        </breadcrumb>
        <transition name="fade-transform" mode="out-in">
            <keep-alive :include="cachedViews">
                <router-view :key="key" class="page-container" />
            </keep-alive>
        </transition>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'

export default {
    // import引入的组件需要注入到对象中才能使用
    name: 'AppMain',
    components: { Breadcrumb },
    data () {
        return {}
    },
    computed: {
        key () {
            return this.$route.path
        },
        breadVisible () {
            const { title } = this.$route.meta || {}
            return ![
                '自定义工作台',
                '工作台',
                '分销工作台',
                'HR工作台',
                '运营工作台',
                '运营管理工作台',
                '培训工作台',
                '财务工作台',
                '采购工作台',
                '供应链工作台',
                '个人中心'
            ].includes(title)
        },
        ...mapState({
            cachedViews: state => state.tagsView.cachedViews,
            customBreadSlot: state => state.transmit.customBreadSlot
        })
    }
}
</script>
