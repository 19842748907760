import Layout from '@/layouts/default'

const routes = [
    {
        name: 'operation',
        path: '/operation',
        component: Layout,
        meta: { title: '运营', noCache: false, afterCrumb: '基础' }, // afterCrumb or beforeCrumb参数可选值可以是 字符串'?title, ?path'，数组['?title', '?path']，对象{?title: '', ?path: ''}
        children: [
            {
                name: 'OperationUpload',
                path: 'upload',
                component: () =>
                    import(
                        /* webpackChunkName: "group-customer" */ './upload/index'
                    ),
                meta: { title: '上传', noCache: false }
            },
            {
                name: 'operation-chart',
                path: 'chart',
                component: () =>
                    import(
                        /* webpackChunkName: "group-customer" */ './chart/index'
                    ),
                meta: {
                    title: '店铺商品分析',
                    noCache: false,
                    customCrumbs: '销售/报表查看'
                }
            },
            {
                name: 'OperationActionLibrary',
                path: 'actions/library',
                component: () =>
                    import(
                        /* webpackChunkName: "group-customer" */ './actions/library/index'
                    ),
                meta: {
                    title: '运营动作库',
                    noCache: false,
                    customCrumbs: '销售/销售2C'
                }
            },
            {
                name: 'OperationActionRecord',
                path: 'actions/record',
                component: () =>
                    import(
                        /* webpackChunkName: "group-customer" */ './actions/record/index'
                    ),
                meta: {
                    title: '运营动作记录',
                    noCache: false,
                    customCrumbs: '销售/销售2C'
                }
            }
        ]
    }
]

export default routes
