<template>
    <div id="app" v-loading="$store.state.fxdata.isLoading">
        <router-view />
    </div>
</template>

<script>
import store from '@/store'

export default {
    name: 'App',
    created () {
        store.dispatch('url/setEntryUrl', {
            entryUrl: window.location.href
        })
    }
}
</script>

<style lang="less">
@import "./styles/index.less";
#app {
    font-family: "Source Han Sans CN", "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
}
</style>
