const getters = {
  currentUser: state => state.user.currentUser,
  currentTenant: state => state.tenant.currentTenant,
  isLoading: state => state.fxdata.isLoading,
  isToast: state => state.fxdata.isToast,
  toastText: state => state.fxdata.toastText,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  entryUrl: state => state.url.entryUrl
}

export default getters
