import Layout from '@/layouts/default'

const Default = () =>
    import(/* webpackChunkName: "group-desktop" */ './default')
const Select = () => import(/* webpackChunkName: "group-desktop" */ './select')
const Operator = () =>
    import(/* webpackChunkName: "group-desktop" */ './operator')
const OperatorManager = () =>
    import(/* webpackChunkName: "group-desktop" */ './operator-manager')
const Distributor = () => import(/* webpackChunkName: "group-desktop" */ './distributor')
const LearningDesktop = () => import(/* webpackChunkName: "group-desktop" */ './learning')

const EhrDesktop = () => import(/* webpackChunkName: "group-desktop" */ './ehrdesktop')
const FinanceDesktop = () => import(/* webpackChunkName: "group-desktop" */ './finance')
const PurchaseDesktop = () => import(/* webpackChunkName: "group-desktop" */ './purchase')
const SupplyChainDesktop = () => import(/* webpackChunkName: "group-desktop" */ './supplyChain')

const LayoutGrid = () => import(/* webpackChunkName: "group-desktop" */ 'components/layout-grid')
const LayoutGridDrop = () => import(/* webpackChunkName: "group-desktop" */ 'components/layout-grid/layout-grid-drop')

const routes = [
    {
        path: '/desktop',
        name: 'desktop',
        component: Layout,
        children: [
            {
                path: '',
                meta: { title: '首页' },
                component: Select
            },
            {
                name: 'DefaultDesktop',
                path: 'default',
                meta: { title: '工作台', affix: true, noCache: false },
                component: Default
            },
            {
                name: 'OperatorManagerDesktop',
                path: 'operator-manager',
                component: OperatorManager,
                meta: {
                    title: '运营管理工作台',
                    allow: 'gbei.desktop.manager',
                    affix: true,
                    noCache: false
                }
            },
            {
                name: 'OperatorDesktop',
                path: 'operator',
                component: Operator,
                meta: {
                    title: '运营工作台',
                    allow: 'gbei.desktop.operator',
                    affix: true,
                    noCache: false
                }
            },
            {
                name: 'DistributorDesktop',
                path: 'distributor',
                component: Distributor,
                meta: {
                    title: '分销工作台',
                    allow: 'gbei.desktop.sells',
                    affix: true,
                    noCache: false
                }
            },
            {
                name: 'LearningDesktop',
                path: 'learning',
                component: LearningDesktop,
                meta: {
                    title: '培训工作台',
                    allow: 'gbei.desktop.learning',
                    affix: true,
                    noCache: false
                }
            },
            {
                name: 'FinanceDesktop',
                path: 'finance',
                component: FinanceDesktop,
                meta: {
                    title: '财务工作台',
                    allow: 'gbei.desktop.finance',
                    affix: true,
                    noCache: false
                }
            },
            {
                name: 'PurchaseDesktop',
                path: 'purchase',
                component: PurchaseDesktop,
                meta: {
                    title: '采购工作台',
                    allow: 'gbei.desktop.purchase',
                    affix: true,
                    noCache: false
                }
            },
            {
                name: 'SupplyChainDesktop',
                path: 'supply-chain',
                component: SupplyChainDesktop,
                meta: {
                    title: '供应链工作台',
                    allow: 'gbei.desktop.supplyChain',
                    affix: true,
                    noCache: false
                }
            },
            {
                name: 'EhrDesktop',
                path: 'ehrdesktop',
                component: EhrDesktop,
                meta: {
                    title: 'HR工作台',
                    allow: 'gbei.desktop.ehr',
                    affix: true,
                    noCache: false
                }
            },
            {
                name: 'LayoutGrid',
                path: 'layout-grid',
                component: LayoutGrid,
                meta: {
                    title: '自定义工作台', // 自定义工作台
                    affix: false,
                    noCache: false,
                    allow: '*'
                }
            },
            {
                name: 'LayoutGridDrop',
                path: 'layout-grid-drop',
                component: LayoutGridDrop,
                meta: {
                    title: '可拖动工作台',
                    affix: true,
                    noCache: false
                }
            }
        ]
    }
]

export default routes
