import Layout from '@/layouts/default'

const routes = [
    {
        path: '/system',
        name: '系统管理',
        component: Layout,
        meta: { title: '系统' },
        children: [
            {
                //配置默认首页
                path: '',
                meta: { allow: "gbei.sys.permission" },
                redirect: 'role'
            },
            {
                path: 'role',
                name: 'roleManage',
                meta: {
                    title: '角色管理',
                    allow: 'gbei.sys.role',
                    noCache: false
                },
                // meta: { title: "角色管理", allow: "*" },
                component: () =>
                    import(/* webpackChunkName: "group-system" */ './role')
            },
            {
                path: 'relation',
                name: 'roleRelation',
                meta: {
                    title: '角色授权',
                    allow: 'gbei.sys.relation',
                    noCache: true
                },
                // meta: { title: "角色授权", allow: "*" },
                component: () =>
                    import(/* webpackChunkName: "group-system" */ './relation')
            },
            {
                path: 'userRole',
                name: 'userRole',
                meta: {
                    title: '用户角色',
                    allow: 'gbei.sys.userrole',
                    noCache: false
                },
                component: () =>
                    import(/* webpackChunkName: "group-system" */ './userRole')
            },
            {
                path: 'resource',
                name: 'resourceMng',
                meta: { title: '资源管理', allow: 'gbei.sys.resource' },
                component: () =>
                    import(/* webpackChunkName: "group-system" */ './resource')
            },
            {
                path: 'user',
                name: 'usersMng',
                meta: { title: '用户管理', allow: 'gbei.sys.user' },
                component: () =>
                    import(/* webpackChunkName: "group-system" */ './user')
            },
            {
                path: 'account',
                name: 'AccountMng',
                meta: {
                    title: '登录账号',
                    allow: 'gbei.sys.account'
                },
                component: () =>
                    import(/* webpackChunkName: "group-system" */ './account')
            },
            {
                path: 'tenant',
                name: 'TenantMng',
                meta: {
                    title: '租户资源',
                    allow: 'gbei.sys.tenant',
                    noCache: true
                },
                component: () =>
                    import(/* webpackChunkName: "group-system" */ './tenant')
            }
        ]
    }
]

export default routes
