import Layout from '@/layouts/default'

const routes = [
    {
        path: '/document',
        name: 'Document',
        component: Layout,
        meta: { title: '文档管理' },
        children: [
            {
                // 配置默认首页
                path: '',
                meta: { },
                redirect: 'group'
            },
            {
                path: 'excelInfo',
                name: 'GroupMng',
                meta: {
                    title: '文档管理',
                    noCache: false
                },
                component: () => import(/* webpackChunkName: "group-oa" */ './excelInfo')
            }

        ]
    }
]

export default routes
