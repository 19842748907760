import Layout from '@/layouts/default'

const routes = [{
    name: 'customer',
    path: '/customer',
    component: Layout,
    meta: { title: '销售', noCache: false, afterCrumb: '基础' }, // afterCrumb or beforeCrumb参数可选值可以是 字符串'?title, ?path'，数组['?title', '?path']，对象{?title: '', ?path: ''}
    children: [
        {
            name: 'customer-goods',
            path: 'goods',
            component: () => import(/* webpackChunkName: "group-customer" */ './goods/index'),
            meta: { title: '商品', noCache: false }
        },
        {
            name: 'customer-channel',
            path: 'channel',
            component: () => import(/* webpackChunkName: "group-customer" */ './channel/index'),
            meta: { title: '渠道', noCache: false }
        },
        {
            name: 'customer-category',
            path: 'category',
            component: () => import(/* webpackChunkName: "group-customer" */ './category/index'),
            meta: { title: '平台品类', noCache: false }
        }
    ]
}]

export default routes
