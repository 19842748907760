const state = {
  currentTenant: {}
}

const mutations = {
  setCurrentTenant (state, tenant) {
    state.currentTenant = tenant
  }
}

const actions = {
  setCurrentTenant ({ commit }, tenant) {
    commit('setCurrentTenant', tenant)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
