import Layout from '@/layouts/default'

const routes = [
    {
        path: '/oa',
        name: 'Oa',
        component: Layout,
        meta: { title: '行政办公' },
        children: [
            {
                // 配置默认首页
                path: '',
                meta: { allow: 'gubei.oa' },
                redirect: 'group'
            },
            {
                path: 'group',
                name: 'GroupMng',
                meta: {
                    title: '组织管理',
                    allow: 'gbei.oa.group',
                    noCache: false
                },
                component: () => import(/* webpackChunkName: "group-oa" */ './group')
            },
            {
                path: 'job',
                name: 'JobMng',
                meta: {
                    title: '岗位管理',
                    allow: 'gbei.oa.job',
                    noCache: false
                },
                component: () => import(/* webpackChunkName: "group-oa" */ './job')
            },
            {
                path: 'employee',
                name: 'EmployeeFile',
                meta: {
                    title: '员工档案',
                    allow: 'gbei.oa.employeeFile',
                    noCache: false
                },
                component: () => import(/* webpackChunkName: "group-oa" */ './employee/index')
            },
            {
                path: 'employee-detail',
                name: 'EmployeeDetail',
                meta: {
                    title: '员工详情',
                    noCache: true
                },
                component: () => import(/* webpackChunkName: "group-oa" */ './employee/detail')
            },
            {
                path: 'employee-add',
                name: 'EmployeeAdd',
                meta: {
                    title: '员工添加',
                    noCache: false
                },
                component: () => import(/* webpackChunkName: "group-oa" */ './employee/detail')
            },
            {
                path: 'assess',
                name: 'AssessList',
                meta: {
                    title: '考核列表',
                    noCache: false
                },
                component: () => import(/* webpackChunkName: "group-oa" */ './assess/index')
            },
            {
                path: 'indicators',
                name: 'IndicatorsList',
                meta: {
                    title: '考核指标',
                    noCache: false
                },
                component: () => import(/* webpackChunkName: "group-oa" */ './indicators/index')
            },
            {
                path: 'assessTemplate',
                name: 'AssessTemplate',
                meta: {
                    title: '考核模板',
                    noCache: false
                },
                component: () => import(/* webpackChunkName: "group-oa" */ './assessTemplate/index')
            },
            {
                path: 'estimate',
                name: 'Astimate',
                meta: {
                    title: '考核列表',
                    noCache: false
                },
                component: () => import(/* webpackChunkName: "group-oa" */ './estimate/index')
            },
            {
                path: 'estimate-detail',
                name: 'EstimateDetail',
                meta: {
                    title: '考核详情',
                    noCache: false
                },
                component: () => import(/* webpackChunkName: "group-oa" */ './estimate/detail')
            },

            {
                path: 'requirementList',
                name: 'RequirementList',
                meta: {
                    title: '岗位需求',
                    noCache: false
                },
                component: () => import(/* webpackChunkName: "group-oa" */ './requirement/index')
            }
        ]
    }
]

export default routes
