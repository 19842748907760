<template>
    <!-- 左上角logo -->
    <div>
        <div
            v-if="hasMenu"
            class="gb-logo hasMenu"
            @click="showMenu"
            :style="{
                background: `url(${logoImageUrl}) center center /contain no-repeat`
            }"
        ></div>
        <div
            v-else
            class="gb-logo"
            @click="gotoDesktop"
            :style="{
                background: `url(${logoImageUrl}) center center /contain no-repeat`
            }"
        ></div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import tenant from '@/components/_tenant'
import { hasPermission } from '@/utils/permission'
import _menu from '@/components/_menu'

export default {
    name: 'Hamburger',
    props: {
        isActive: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            logoImageUrl: ''
        }
    },
    computed: {
        ...mapGetters(['currentTenant']),
        hasMenu() {
            return hasPermission('gbei.desktop.menu')
        }
    },
    created() {
        console.log(this.hasMenu)
    },
    async mounted() {
        await tenant.initTenant()
        this.logoImageUrl = tenant.getTenantSettingValue(
            'logo_image',
            this.currentTenant,
            this.logoImageUrl
        )
    },
    methods: {
        gotoDesktop() {
            _menu.gotoDesktop(this, true)
        },
        showMenu() {
            console.log('===')
            this.$emit('showMenu')
        }
    }
}
</script>

<style scoped lang="less">
.hamburger {
    display: inline-block;
    height: 20px;
    vertical-align: middle;
}
.gb-logo {
    cursor: pointer;
    width: 100px;
    height: 50px;
    text-align: center;
    background-color: #fff;
    margin-left: 10px;
    // padding: 0 12px;
    img {
        width: 100%;
        height: 50%;
        margin-top: 12px;
    }
}
.hamburger.is-active {
    transform: rotate(180deg);
}
.beta-text {
    display: flex;
    align-items: center;
    width: 30px;
    height: 30px;
    font-size: 12px;
    line-height: 1.2;
    color: #69acf1;
    text-align: center;
    border: 1px solid #69acf1;
}
</style>
