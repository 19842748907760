<!--登录等全页面的布局-->
<template>
    <div class="gb-console">
        <router-view />
    </div>
</template>

<script>
export default {}
</script>

<style lang="less">
.gb-console {
    height: 100%;
    position: relative;
}
</style>
