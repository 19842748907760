import userSvc from 'services/user'
import store from '@/store'
import { resetRouter } from '@/router'

const state = {
    currentUser: {}
}

const mutations = {
    SET_CURRENT_USER(state, user) {
        state.currentUser = user
    },
    SET_TOKEN: (state, token) => {
        state.token = token
    }
}

const actions = {
    setCurrentUser({ commit }, user) {
        commit('SET_CURRENT_USER', user)
    },
    // user logout
    logout({ commit, state }) {
        return new Promise((resolve, reject) => {
            userSvc
                .logout()
                .then(() => {
                    resetRouter()
                    commit('SET_CURRENT_USER', {})
                    store.dispatch('tagsView/logOutDel', null, {
                        root: true
                    })
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
